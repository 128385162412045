.main-menu.menu {
  margin-top: 0;
  border-radius: 0;
}
.sub-menu.sidebar {
  top: 40px;
}
.sub-menu .item {
  text-align: left !important;
}
.ui.labeled.icon.sub-menu .item > .icon:not(.dropdown) {
  float: left;
  font-size: 1em !important;
  margin-right: 10px !important;
}
